<template>
  <el-dialog
    custom-class="detailDialog"
    :close-on-click-modal="false"
    center
    width="1200px"
    title="防爆合格证"
    :visible.sync="visible"
  >
    <div
      style="
        width: 137px;
        height: 121px;
        position: absolute;
        right: 63px;
        top: 82px;
      "
      :class="isValiable ? 'yes' : 'no'"
    ></div>
    <el-form ref="form" v-model="formData" label-width="130px">
      <el-form-item label="防爆证编号：">
        <div>{{ formData.numbers }}</div>
      </el-form-item>
      <el-form-item label="生产单位名称：">
        <div>{{ formData.companyName }}</div>
      </el-form-item>
      <el-form-item label="产品名称：">
        <div>{{ formData.productName }}</div>
      </el-form-item>
      <el-form-item label="产品型号：">
        <div>{{ formData.productNumber }}</div>
      </el-form-item>
      <el-form-item label="产品规格：">
        <div>{{ formData.productSpecification }}</div>
      </el-form-item>
      <el-form-item label="防爆认证标志：">
        <div>{{ formData.certificationMark }}</div>
      </el-form-item>
      <el-form-item label="截止日期：">
        <div>{{ formData.endDate }}</div>
      </el-form-item>
      <el-form-item label="覆盖产品：">
        <div>{{ formData.coverageProducts }}</div>
      </el-form-item>
      <el-form-item label="发证单位：">
        <div>{{ formData.issuingUnit }}</div>
      </el-form-item>
      <el-form-item label="备注：">
        <div>{{ formData.remarks }}</div>
      </el-form-item>
      <div
        v-for="(v, i) in formData.pictures"
        :key="i"
        style="
          position: relative;
          margin-bottom: 10px;
          display: inline-block;
          margin-right: 20px;
        "
      >
        <el-image
          class="picView"
          :src="v.picReqUrl"
          :preview-src-list="pictures"
        >
        </el-image>
        <span style="color: #007bc7">{{ `附件${i + 1}：${v.fileName}` }}</span>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  data(){
    return{
      visible:false
    }
  },
  computed: {
    pictures() {
      let pics = this.formData.pictures.map((v) => v.picReqUrl);
      return pics;
    },
  },
  props: {
    isValiable: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: () => {},
    }
  },
};
</script>
